<template>
  <IAmOverlay :loading="loading">
    <div style="min-width: 1080px;">
      <TicketConfig
        :config="config"
        style="top: 48px; margin-top: 1rem"
      />

      <b-card
        no-body
        header-tag="header"
        border-variant="info"
        header-bg-variant="light-info"
        header-class="py-1"
        class="border mt-1"
      >
        <template #header>
          <h4 class="m-0">
            {{ $t('ticket.sectionsTitle.template') }}
          </h4>
        </template>

        <b-card-body
          class="p-1 bg-light"
          style="border-bottom-right-radius: inherit; border-bottom-left-radius: inherit;"
        >
          <ElectronicTicket
            :frontend-i18n="true"
            :config.sync="config"
            :from-flights="flights"
            :paxs="paxs"
            :price="price"
            :is-config="true"
            @resetNote="resetNote"
            @resetAgencyInfo="resetAgencyInfo"
          />
          <div class="d-flex justify-content-center">
            <b-button
              variant="primary"
              class="mt-1"
              @click="saveTicketConfig()"
            >
              {{ $t('save') }}
            </b-button>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </IAmOverlay>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import { BCard, BCardBody, BButton } from 'bootstrap-vue'

import {
  FLIGHTS_INFO_SAMPLE,
  PAXS_SAMPLE,
  PRICE_SAMPLE,
} from '@/constants/ticket'
import service from '@/api/service'
import { dataURLtoFile, uploadFilesDocuments } from '@/@core/utils/utils'

import useToast from '@useToast'

import useGetTicketConfig from './useGetTicketConfig'

export default {
  name: 'ElectronicTicketTemplateSettings',

  components: {
    BCard,
    BCardBody,
    BButton,
    TicketConfig: () => import('./TicketConfig.vue'),
    ElectronicTicket: () => import('./ElectronicTicket.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },

  setup(_, { root }) {
    const {
      config,
      employeeId,
      getTicketConfig,
    } = useGetTicketConfig()

    if (root.isMobileView) {
      const viewport = document.querySelector('meta[name=viewport]')
      const documentWidth = document.body.clientWidth
      const eticketPageWidth = 1080
      const ratio = documentWidth ? Number((documentWidth) / eticketPageWidth).toFixed(1) : '0.3'
      viewport.setAttribute('content', `width=device-width, initial-scale=${ratio}, maximum-scale=1.0, shrink-to-fit=no`)
    }
    onUnmounted(() => {
      const viewport = document.querySelector('meta[name=viewport]')
      const contentViewport = viewport.getAttribute('content')
      if (contentViewport !== 'width=device-width, initial-scale=1.0, maximum-scale=1.0') {
        viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0')
      }
    })

    const { toastError, toastSuccess } = useToast()
    const flights = ref(FLIGHTS_INFO_SAMPLE)
    const paxs = ref(PAXS_SAMPLE)
    const price = ref(PRICE_SAMPLE)
    const loading = ref(false)
    async function saveTicketConfig() {
      loading.value = true
      try {
        // upload image and replace in agencyInfo
        const imageArr = [...config.value.agencyInfo.matchAll(/<img[^>]+src="(data:image\/png;base64,[^">]+)"/g)].map(match => match?.[1])
        if (imageArr.length > 0) {
          await Promise.all(imageArr.map(async (img, index) => {
            const file = dataURLtoFile(img, index)
            const uploadedFile = await uploadFilesDocuments([file])
            config.value.agencyInfo = config.value.agencyInfo.replace(img, uploadedFile[0]?.url)
          }))
          config.value.agencyInfo = config.value.agencyInfo.replace(/<img(?!.*\bclass\b)([^>]*\bsrc="[^">]*)/g, '<img class="fit"$1')
        }

        await service.post(`/employees/${employeeId.value}/ticket-config`, config.value)
        getTicketConfig()
        toastSuccess('ticket.saveTicketConfigSuccess')
      } catch (error) {
        console.error({ error })
        toastError('ticket.saveTicketConfigErr')
      } finally {
        loading.value = false
      }
    }
    function resetNote() {
      this.$bvModal
        .msgBoxConfirm('Xác nhận đặt lại ghi chú về mặc định?', {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            if (config.value.locale === 'vi') {
              config.value.note = ''
            } else {
              config.value.noteEn = ''
            }
            saveTicketConfig()
          }
        })
    }

    function resetAgencyInfo() {
      this.$bvModal
        .msgBoxConfirm('Xác nhận đặt lại thông tin đại lý về mặc định?', {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            if (config.value.locale === 'vi') {
              config.value.agencyInfo = ''
            } else {
              config.value.agencyInfoEn = ''
            }
            saveTicketConfig()
          }
        })
    }

    return {
      loading,
      config,
      flights,
      paxs,
      price,
      saveTicketConfig,
      resetNote,
      resetAgencyInfo,
    }
  },
}
</script>
